@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-paragraph;
  }
  a {
    @apply hover:text-orange transition;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-heading;
  }
  .h1 {
    @apply text-[36px] leading-[46px] lg:text-[65px] lg:leading-[80px] font-extrabold;
  }
  .h2 {
    @apply text-[26px] leading-[36px] lg:text-[50px] lg:leading-[60px] font-bold;
  }
  .h3 {
    @apply text-[18px] leading-[28px] lg:text-[34px] lg:leading-[44px] font-semibold;
  }
  .section-sm {
    @apply py-[70px];
  }
  .section-lg {
    @apply py-[150px];
  }
  .btn {
    @apply font-medium rounded text-lg bg-shape;
  }
  .btn-sm {
    @apply h-[48px] px-[25px];
  }
  .btn-md {
    @apply h-[50px] px-[16px];
  }
  .btn-lg {
    @apply h-[58px] rounded-md px-[40px];
  }
  .btn-orange {
    @apply bg-orange text-white hover:bg-orange-hover transition;
  }
  .form-control {
    @apply h-[58px] border border-stroke-2 rounded pl-6 placeholder:text-paragraph text-paragraph outline-none;
  }
  .gc {
    font-size: 65px;
    font-weight: bold;
    background: -webkit-linear-gradient(#f12711, #f5af19);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
